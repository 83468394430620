<template>
  <div>
    <b-overlay :show="isLoading || isSaving">
      <p
        v-if="asset"
        class="text-muted"
        style="margin-top: -15px;"
      >
        {{ asset.systemNumber }} / {{ asset.manufacturer }}  / {{ asset.productFamily }} / {{ asset.model }}
      </p>

      <b-card
        v-if="incidentCreateInfo.ticketCreationEnabled"
      >

        <form-wizard
          :color="variables.primary"
          :title="null"
          :subtitle="null"
          step-size="xs"
          shape="square"
          finish-button-text="Submit"
          back-button-text="Previous"
          class="mb-3"
        >
          <tab-content
            title="System details"
            :before-change="validateSystemDetails"
          >
            <validation-observer
              ref="systemDetailRules"
              tag="form"
            >
              <h2 class="mb-2">
                Enter your system details
              </h2>
              <b-row>
                <b-col>
                  <validation-provider
                    v-slot="validationContext"
                    name="hostname"
                    :rules="{ required: true, max: 200 }"
                  >
                    <b-form-group
                      label="Hostname"
                      label-for="input-hostname"
                    >
                      <b-form-input
                        id="input-hostname"
                        v-model="supportInfo.hostname"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-hostname-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-hostname-live-feedback">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                </b-col>
                <b-col>
                  <validation-provider
                    v-slot="validationContext"
                    name="version"
                    :rules="{ required: true, max: 50 }"
                  >
                    <b-form-group
                      label="Version"
                      label-for="input-version"
                    >
                      <b-form-input
                        id="input-version"
                        v-model="supportInfo.version"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-version-live-feedback"
                      />
                      <b-form-invalid-feedback id="input-version-live-feedback">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>

              <validation-provider
                v-if="showBackupApplicationInput"
                v-slot="validationContext"
                name="backupApplication"
                :rules="{ required: false, max: 100 }"
              >
                <b-form-group
                  label="Backup Application"
                  label-for="input-backupApplication"
                  description="Backup Application using the DataDomain Restorer"
                >
                  <b-form-input
                    id="input-backupApplication"
                    v-model="supportInfo.backupApplication"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-backupApplication-live-feedback"
                  />
                  <b-form-invalid-feedback id="input-backupApplication-live-feedback">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider
                v-slot="validationContext"
                name="environmentdescription"
                :rules="{ required: false, max: 5000 }"
              >
                <b-form-group
                  label="Environment description"
                  label-for="input-environmentdescription"
                  description="Special or helpful information about the environment"
                >
                  <b-form-textarea
                    id="input-environmentdescription"
                    v-model="supportInfo.environmentDescription"
                    rows="8"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-environmentdescription-live-feedback"
                  />
                  <b-form-invalid-feedback id="input-environmentdescription-live-feedback">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </validation-observer>
          </tab-content>

          <tab-content
            title="Issue description"
            :before-change="validateIssueDescription"
          >
            <validation-observer
              ref="issueDescriptionRules"
              tag="form"
            >
              <h2 class="mb-2">
                Details
              </h2>

              <b-row>
                <b-col>
                  <validation-provider
                    v-slot="validationContext"
                    name="severity"
                    :rules="{ required: true }"
                  >
                    <b-form-group
                      label="Severity"
                      label-for="input-severity"
                    >
                      <b-form-select
                        id="input-severity"
                        v-model="issueDescription.severity"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-severity-live-feedback"
                        :options="severityOptions"
                      />
                      <b-form-invalid-feedback id="input-severity-live-feedback">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col>
                  <dl>
                    <dt>Severity 1 - Critical</dt>
                    <dd>
                      Breakdown in production environment, business impact in production system. e.g.: Restore failure of productive data or users cannot access productive data.
                      We need your interaction immediately within SLA´s, otherwise ticket will be set to a lower severity.
                    </dd>
                    <dt>Severity 2 - Medium</dt>
                    <dd>
                      Functional disorder, no actual business impact in productive environment.
                      e.g.: Backup problem in environment, partial backup failure, failure to archive data.
                    </dd>
                    <dt>Severity 3 - Low</dt>
                    <dd>
                      No critical functional disorder. No business impact.
                    </dd>
                  </dl>
                </b-col>
              </b-row>

              <b-alert
                variant="info"
                :show="issueDescription.bookOnContingent"
              >
                <div class="alert-body">
                  <span v-if="isHost">Please inform the customer that costs will be incurred.</span>
                  <span v-else>Please note that costs will be incurred.</span>
                </div>
              </b-alert>

              <b-row
                v-if="incidentCreateInfo.activeContingents.length > 0"
                class="mb-1 mt-1"
              >
                <b-col>
                  <b-form-checkbox
                    v-model="issueDescription.bookOnContingent"
                    name="input-bookoncontingent"
                    switch
                    :disabled="contingentDisabled"
                  >
                    Book on contingent
                  </b-form-checkbox>
                </b-col>
                <b-col>
                  <dl>
                    <b-alert
                      variant="warning"
                      :show="incidentCreateInfo.contingentIsOverbooked"
                    >
                      <div class="alert-body">
                        <span>The contingent is overbooked!</span>
                      </div>
                    </b-alert>
                    <template
                      v-for="contingent in incidentCreateInfo.activeContingents"
                    >
                      <dt>{{ contingent.name }}</dt>
                      <dd>{{ contingent.remainingHours }} of {{ contingent.orderedHours }} hours left</dd>
                    </template>
                  </dl>
                </b-col>
              </b-row>

              <b-form-checkbox
                v-model="issueDescription.isNewInstallation"
                class="mb-1 mt-1"
                name="input-isNewInstallation"
                switch
              >
                New installation?
              </b-form-checkbox>

              <validation-provider
                v-slot="validationContext"
                name="customerReferenceId"
                :rules="{ required: false, max: 120 }"
              >
                <b-form-group
                  label="Customer Ticket Reference Id"
                  label-for="input-customerReferenceId"
                  description="Insert your ticket id of your own system to match mentIQ tickets with your custom system."
                >
                  <b-form-input
                    id="input-customerReferenceId"
                    v-model="issueDescription.customerReferenceId"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-customerReferenceId-live-feedback"
                  />
                  <b-form-invalid-feedback id="input-customerReferenceId-live-feedback">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <hr>

              <div v-if="isHost">
                <h2 class="mb-2 mt-2">
                  Overwrite ticket creator
                </h2>
                <b-alert
                  variant="info"
                  show
                >
                  <div class="alert-body">
                    <span>If you set the customer email, the ticket will be created in name of the customer.</span>
                  </div>
                </b-alert>

                <ContactSelectModal @selected="useSelectedContact" />

                <validation-provider
                  v-slot="validationContext"
                  name="overwriteCustomerEmail"
                  :rules="{ required: false, max: 200 }"
                >
                  <b-form-group
                    label="Customer Email"
                    label-for="input-overwriteCustomerEmail"
                  >
                    <b-input-group>
                      <b-form-input
                        id="input-overwriteCustomerEmail"
                        v-model="issueDescription.overwriteCustomerEmail"
                        :state="getValidationState(validationContext)"
                        aria-describedby="input-overwriteCustomerEmail-live-feedback"
                      />
                      <b-input-group-append>
                        <b-button
                          v-b-modal="'contact-select-modal'"
                          variant="outline-info"
                        >
                          Search contact
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <b-form-invalid-feedback id="input-overwriteCustomerEmail-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  v-slot="validationContext"
                  name="overwriteCustomerFirstname"
                  :rules="{ required: false, max: 200 }"
                >
                  <b-form-group
                    label="Customer Firstname"
                    label-for="input-overwriteCustomerFirstname"
                  >
                    <b-form-input
                      id="input-overwriteCustomerFirstname"
                      v-model="issueDescription.overwriteCustomerFirstname"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-overwriteCustomerFirstname-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-overwriteCustomerFirstname-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
                <validation-provider
                  v-slot="validationContext"
                  name="overwriteCustomerLastname"
                  :rules="{ required: false, max: 200 }"
                >
                  <b-form-group
                    label="Customer Lastname"
                    label-for="input-overwriteCustomerLastname"
                  >
                    <b-form-input
                      id="input-overwriteCustomerLastname"
                      v-model="issueDescription.overwriteCustomerLastname"
                      :state="getValidationState(validationContext)"
                      aria-describedby="input-overwriteCustomerLastname-live-feedback"
                    />
                    <b-form-invalid-feedback id="input-overwriteCustomerLastname-live-feedback">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </div>

              <hr>

              <h2 class="mb-2 mt-2">
                Ticket description
              </h2>
              <validation-provider
                v-slot="validationContext"
                name="title"
                :rules="{ required: true, max: 250 }"
              >
                <b-form-group
                  label="Ticket title"
                  label-for="input-title"
                  description="Short description of the issue"
                >
                  <b-form-input
                    id="input-title"
                    v-model="issueDescription.title"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-title-live-feedback"
                  />
                  <b-form-invalid-feedback id="input-title-live-feedback">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider
                v-slot="validationContext"
                name="description"
                :rules="{ required: true, max: 10000 }"
              >
                <b-form-group
                  label="Description"
                  label-for="input-description"
                  description="Describe the issue as specific as possible"
                >
                  <b-form-textarea
                    id="input-description"
                    v-model="issueDescription.description"
                    rows="8"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-description-live-feedback"
                  />
                  <b-form-invalid-feedback id="input-description-live-feedback">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider
                v-if="isHost"
                v-slot="validationContext"
                name="note"
                :rules="{ required: false, max: 2000 }"
              >
                <b-form-group
                  label="Note"
                  label-for="input-note"
                  description="Internal note for mentIQ"
                >
                  <b-form-textarea
                    id="input-note"
                    v-model="issueDescription.note"
                    rows="4"
                    :state="getValidationState(validationContext)"
                    aria-describedby="input-note-live-feedback"
                  />
                  <b-form-invalid-feedback id="input-note-live-feedback">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <h3 class="mb-2 mt-2">
                Attachments
              </h3>
              <ul v-if="issueDescription.attachments.length > 0">
                <li
                  v-for="(attachment, key) in issueDescription.attachments"
                  :key="attachment.id"
                >
                  {{ attachment.name }}
                  &nbsp;
                  <a
                    href="#"
                    title="Delete"
                    @click="$delete(issueDescription.attachments, key)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                    />
                  </a>
                </li>
              </ul>

              <b-form-group
                label="Attach files"
              >
                <b-form-file
                  v-model="uploadFile"
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                />

                <b-progress
                  v-if="uploadFile"
                  v-model="uploadProgress"
                  :max="uploadFile.size"
                />
              </b-form-group>

            </validation-observer>
          </tab-content>

          <tab-content
            title="Summary"
          >
            <h2 class="mb-2">
              Review your input
            </h2>

            <dl
              v-if="supportInfo.hostname"
              class="row"
            >
              <dt class="col-sm-3 text-right">
                Hostname
              </dt>
              <dd class="col-sm-9">
                {{ supportInfo.hostname }}
              </dd>
            </dl>

            <dl
              v-if="supportInfo.version"
              class="row"
            >
              <dt class="col-sm-3 text-right">
                Version
              </dt>
              <dd class="col-sm-9">
                {{ supportInfo.version }}
              </dd>
            </dl>

            <dl
              v-if="supportInfo.backupApplication"
              class="row"
            >
              <dt class="col-sm-3 text-right">
                Backup Application
              </dt>
              <dd class="col-sm-9">
                {{ supportInfo.backupApplication }}
              </dd>
            </dl>

            <dl
              v-if="supportInfo.environmentDescription"
              class="row"
            >
              <dt class="col-sm-3 text-right">
                Environment Description
              </dt>
              <dd class="col-sm-9">
                {{ supportInfo.environmentDescription }}
              </dd>
            </dl>

            <dl class="row">
              <dt class="col-sm-3 text-right">
                Severity
              </dt>
              <dd class="col-sm-9">
                {{ selectedSeverityText }}
              </dd>
            </dl>

            <dl class="row">
              <dt class="col-sm-3 text-right">
                Book on contingent
              </dt>
              <dd class="col-sm-9">
                {{ issueDescription.bookOnContingent === true ? 'Yes' : 'No' }}
              </dd>
            </dl>

            <dl class="row">
              <dt class="col-sm-3 text-right">
                New Installation?
              </dt>
              <dd class="col-sm-9">
                {{ issueDescription.isNewInstallation === true ? 'Yes' : 'No' }}
              </dd>
            </dl>

            <dl class="row">
              <dt class="col-sm-3 text-right">
                Customer Ticket Reference Id
              </dt>
              <dd class="col-sm-9">
                {{ issueDescription.customerReferenceId }}
              </dd>
            </dl>

            <dl
              v-if="isHost"
              class="row"
            >
              <dt class="col-sm-3 text-right">
                Overwrite Creator
              </dt>
              <dd class="col-sm-9">
                {{ overwriteCreator === true ? 'Yes' : 'No' }}
                <div v-if="overwriteCreator">
                  <br>
                  <strong>Email:</strong> {{ issueDescription.overwriteCustomerEmail }}<br>
                  <strong>Firstname:</strong> {{ issueDescription.overwriteCustomerFirstname }}<br>
                  <strong>Lastname:</strong> {{ issueDescription.overwriteCustomerLastname }}
                </div>
              </dd>
            </dl>

            <dl class="row">
              <dt class="col-sm-3 text-right">
                Ticket Title
              </dt>
              <dd class="col-sm-9">
                {{ issueDescription.title }}
              </dd>
            </dl>

            <dl class="row">
              <dt class="col-sm-3 text-right">
                Description
              </dt>
              <dd
                class="col-sm-9"
                style="white-space: pre-line"
              >
                {{ issueDescription.description }}
              </dd>
            </dl>

            <dl
              v-if="isHost && issueDescription.note"
              class="row"
            >
              <dt class="col-sm-3 text-right">
                Note
              </dt>
              <dd
                class="col-sm-9"
                style="white-space: pre-line"
              >
                {{ issueDescription.note }}
              </dd>
            </dl>

            <dl class="row">
              <dt class="col-sm-3 text-right">
                Attachments
              </dt>
              <dd class="col-sm-9">
                <ul v-if="issueDescription.attachments.length > 0">
                  <li
                    v-for="attachment in issueDescription.attachments"
                    :key="attachment.id"
                  >
                    {{ attachment.name }}
                  </li>
                </ul>
              </dd>
            </dl>
          </tab-content>

          <template
            slot="footer"
            slot-scope="props"
          >
            <div class="wizard-footer-left">
              <wizard-button
                v-if="props.activeTabIndex > 0"
                :style="props.fillButtonStyle"
                @click.native="props.prevTab()"
              >
                Previous
              </wizard-button>
            </div>
            <div class="wizard-footer-right">
              <wizard-button
                v-if="!props.isLastStep"
                class="wizard-footer-right"
                :style="props.fillButtonStyle"
                @click.native="props.nextTab()"
              >
                Next
              </wizard-button>

              <wizard-button
                v-else
                class="wizard-footer-right finish-button"
                :style="props.fillButtonStyle"
                @click.native="save"
              >
                Open Ticket
              </wizard-button>
            </div>
          </template>
        </form-wizard>

        <hr>

        <div
          v-if="incidentCreateInfo.partnerSupportTicketCreationDescription"
          v-html="incidentCreateInfo.partnerSupportTicketCreationDescription"
        />

      </b-card>
      <b-card
        v-if="!incidentCreateInfo.ticketCreationEnabled"
        :title="null"
      >
        <p
          v-if="incidentCreateInfo.assetIsExpired"
        >
          The service contract is not valid.
        </p>

        <div
          v-html="incidentCreateInfo.partnerSupportTicketCreationDescription"
        />

      </b-card>
    </b-overlay>

    <b-modal
      ref="gracePeriodInfoModal"
      title="Attention"
      no-close-on-backdrop
      ok-only
      header-bg-variant="info"
    >
      <p>
        Der Wartungsvertrag zu diesem System ist abgelaufen.
        Dennoch werden wir als mentIQ versuchen, Ihnen bei diesem Problem zu helfen.
        Wir können nicht garantieren, dass wir bei Drittanbietern oder Herstellern weitere Supportleistungen in Anspruch nehmen können,
        falls es zur Bearbeitung erforderlich ist.<br>
        Sollten Sie bereits eine Beauftragung für eine Wartungsverlängerung erteilt haben,
        dann ist dieser Hinweis irrelevant.
        Sollte keine Wartungsverlängerung erteilt worden sein,
        dann wenden Sie sich bitte an den mentIQ Vertrieb unter 089 - 95 415 72 – 20 oder per Email an <a href="mailto:info@mentiq.com">info@mentiq.com</a>.
      </p>
    </b-modal>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import {
  BRow, BCol, BCard, BOverlay, BFormGroup, BFormTextarea, BFormInput, BFormInvalidFeedback, BFormSelect, BFormCheckbox, BInputGroup, BInputGroupAppend, BButton, BAlert, BFormFile, BProgress, BModal,
} from 'bootstrap-vue'

import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import {
  // eslint-disable-next-line no-unused-vars
  required, max, mapServerFieldValidationErrors,
} from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import ContactSelectModal from '@/views/contacts/ContactSelectModal.vue'

import AssetService from '@/service/asset.service'
import IncidentService from '@/service/incident.service'
import DailyCheckupService from '@/service/dailycheckup.service'

import variables from '@/assets/scss/variables/_variables.scss'

const { BlockBlobClient } = require('@azure/storage-blob')

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BOverlay,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BFormInvalidFeedback,
    BFormSelect,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BButton,
    BAlert,
    BFormFile,
    BProgress,
    BModal,
    FormWizard,
    TabContent,
    WizardButton,
    ValidationObserver,
    ValidationProvider,
    ContactSelectModal,
  },
  data() {
    return {
      variables,
      isLoading: false,
      isSaving: false,
      incidentCreateInfo: {},
      asset: {},
      supportInfo: {
        hostname: null,
        version: null,
        backupApplication: null,
        environmentDescription: null,
      },
      issueDescription: {
        severity: null,
        bookOnContingent: false,
        isNewInstallation: false,
        customerReferenceId: null,
        overwriteCustomerEmail: null,
        overwriteCustomerFirstname: null,
        overwriteCustomerLastname: null,
        title: null,
        description: null,
        attachments: [],
        note: null,
      },
      severityOptions: [
        { value: 1, text: 'Severity 1 - Critical' },
        { value: 2, text: 'Severity 2 - Medium' },
        { value: 3, text: 'Severity 3 - Low' },
      ],
      uploadProgress: 0,
      uploadFile: null,
    }
  },
  computed: {
    ...mapGetters({
      isHost: 'auth/isHost',
    }),
    showBackupApplicationInput() {
      return this.asset.productFamily === 'DataDomain'
    },
    isBookingOnContingentForced() {
      // Force using contingent to book if ticket can only be created because of open contingents. If asset is enabled to create tickets, user can choose wether to use contingent or not
      return this.incidentCreateInfo.assetCanCreateTicket === false
    },
    overwriteCreator() {
      return this.issueDescription.overwriteCustomerEmail != null
    },
    selectedSeverityText() {
      if (this.issueDescription.severity) {
        return this.severityOptions.find(x => x.value === this.issueDescription.severity).text
      }

      return 'Not selected'
    },
    contingentDisabled() {
      // if booking on contingent is forced, disable checkbox so user can not unset it
      if (this.isBookingOnContingentForced === true) {
        return true
      }

      // disable it if contingent is overbooked and user is Tenant user (HOST users still can create contingent tickets, even if its overbooked)
      if (this.incidentCreateInfo.contingentIsOverbooked && !this.isHost) {
        return true
      }

      return false
    },
  },
  watch: {
    uploadFile(val) {
      if (val) {
        this.uploadAttachment(val)
      }
    },
  },
  mounted() {
    this.isLoading = true

    Promise.all([
      IncidentService.getCreateInfoAsync(this.$route.params.id, { disableTenantFilter: true }),
      AssetService.getAsync(this.$route.params.id, { disableTenantFilter: true }),
    ])
      .then(result => {
        [this.incidentCreateInfo, this.asset] = result

        this.supportInfo = this.asset.supportInfo || {}

        // prefill overwrite support contact for HOST users
        if (this.isHost && this.asset.primarySupportContact) {
          this.issueDescription.overwriteCustomerEmail = this.asset.primarySupportContact.email
          this.issueDescription.overwriteCustomerFirstname = this.asset.primarySupportContact.firstname
          this.issueDescription.overwriteCustomerLastname = this.asset.primarySupportContact.lastname
        }

        // Overwrite support info hostname and version with data of asset if available
        if (this.asset.hostname) {
          this.supportInfo.hostname = this.asset.hostname
        }
        if (this.asset.version) {
          this.supportInfo.version = this.asset.version
        }

        if (!this.supportInfo.version) {
          this.supportInfo.version = this.asset.version
        }

        if (this.isBookingOnContingentForced) {
          this.issueDescription.bookOnContingent = true
        }

        if (this.asset.isInGracePeriod === true) {
          this.$refs.gracePeriodInfoModal.show()
        }

        if (this.$route.query.checkupId) {
          DailyCheckupService.getAsync(this.$route.query.checkupId, { disableTenantFilter: true })
            .then(dailyCheckupResult => {
              this.issueDescription.description = dailyCheckupResult.note
            })
        }
      })
      .finally(() => {
        this.isLoading = false
      })
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null
    },
    async uploadAttachment(file) {
      IncidentService.getAttachmentUploadLinkAsync(file.name, { disableTenantFilter: true })
        .then(result => {
          // eslint-disable-next-line no-unused-vars
          const { uploadUrl, attachmentId } = result
          const self = this

          const blockBlobClient = new BlockBlobClient(uploadUrl)
          blockBlobClient.uploadData(file, {
            onProgress(event) {
              self.uploadProgress = event.loadedBytes
              self.$log.debug('Progress', event.loadedBytes)
            },
            blobHTTPHeaders: {
              blobContentType: file.type,
            },
          })
            .then(() => {
              // Upload finished, add to attachment list and clear fileUpload for new upload
              const fileNameMaxLength = 200
              const fileName = file.name.length <= fileNameMaxLength ? file.name : file.name.slice(0, fileNameMaxLength)
              self.issueDescription.attachments.push({
                id: attachmentId,
                name: fileName,
              })

              self.uploadProgress = 0
              self.uploadFile = null
            })
        })
    },
    validateSystemDetails() {
      return new Promise((resolve, reject) => {
        this.$refs.systemDetailRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
    validateIssueDescription() {
      return new Promise((resolve, reject) => {
        this.$refs.issueDescriptionRules.validate().then(success => {
          if (success) {
            // CheckScript is required
            if (this.uploadFile !== null) {
              this.$toast.warning('A file upload is still in progress.', {
                icon: true,
              })
              reject()
            } else {
              resolve(true)
            }
          } else {
            reject()
          }
        })
      })
    },
    // eslint-disable-next-line no-unused-vars
    useSelectedContact(event) {
      this.issueDescription.overwriteCustomerEmail = event.data.email
      this.issueDescription.overwriteCustomerFirstname = event.data.firstname
      this.issueDescription.overwriteCustomerLastname = event.data.lastname
    },
    save() {
      this.isSaving = true

      const data = {
        title: this.issueDescription.title,
        description: this.issueDescription.description,
        severity: this.issueDescription.severity,
        customerRefId: this.issueDescription.customerReferenceId,
        assetId: this.asset.id,
        assetSupportInfo: this.supportInfo,
        overwriteCreator: this.overwriteCreator,
        creatorEmail: this.issueDescription.overwriteCustomerEmail,
        creatorFirstname: this.issueDescription.overwriteCustomerFirstname,
        creatorLastname: this.issueDescription.overwriteCustomerLastname,
        isContingent: this.issueDescription.bookOnContingent,
        note: this.issueDescription.note,
        attachments: this.issueDescription.attachments,
      }

      const self = this
      IncidentService.createAsync(data, { disableTenantFilter: true })
        .then(
          result => {
            this.$swal({
              title: `Ticket MIQ-T-${result.number} created`,
              icon: 'info',
            })
              .then(clickResult => {
                if (clickResult.isConfirmed) {
                  // if returnPath is defined in query, we navigate to this path (e.g. coming from DailyCheckModal). otherwise we redirect to created incident detail page
                  if (self.$route.query.returnPath) {
                    self.$router.push({ path: self.$route.query.returnPath })
                  } else {
                    self.$router.push({ name: 'incident-detail', params: { id: result.id } })
                  }
                }
              })
          },
          err => {
            this.$swal({
              title: 'Something went wrong!',
              text: err.response.data.error.message,
              icon: 'error',
            })
          },
        )
        .finally(() => {
          this.isSaving = false
        })
    },
  },
}

</script>
