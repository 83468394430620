// eslint-disable-next-line import/no-cycle
import axios from '@/libs/axios'
import { buildAxiosRequestConfig } from './serviceManager'

export default {
  async getAsync(id) {
    const result = await axios.get(`/core/contact/${id}`)
    return result.data
  },

  async getAllListAsync(_options, config) {
    const pageSize = 1000
    let page = 0
    let totalCount = 0

    const result = []

    do {
      // eslint-disable-next-line no-await-in-loop
      const response = await axios.get(`/core/contact/?MaxResultCount=${pageSize}&SkipCount=${page * pageSize}`, buildAxiosRequestConfig(config))
      totalCount = response.data.totalCount
      result.push(...response.data.items)

      page += 1
    } while (result.length < totalCount)

    return result
  },
}
