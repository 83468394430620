<template>
  <div>
    <b-modal
      id="contact-select-modal"
      v-model="modalShow"
      size="xl"
      @show="loadData"
    >
      <Grid
        class="grid-full-height"
        :data-items="result"
        :columns="columns"
        :take="dataState.take"
        :skip="dataState.skip"
        :sort="dataState.sort"
        :filter="dataState.filter"
        :group="dataState.group"
        :sortable="true"
        :reorderable="true"
        :resizable="true"
        :groupable="false"
        :pageable="true"
        :filterable="true"
        :page-size="50"
        :selected-field="selectedField"
        @datastatechange="dataStateChange"
        @rowclick="onRowClick"
      />

      <template #modal-footer="{ ok, cancel }">
        <b v-if="selectedItem">Selected contact: {{ selectedItem.lastname }} {{ selectedItem.firstname }}</b>

        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button
          variant="primary"
          @click="useSelected()"
        >
          Use selected contact
        </b-button>
        <b-button
          @click="cancel()"
        >
          Cancel
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>

import {
  BModal,
  BButton,
} from 'bootstrap-vue'
import { process } from '@progress/kendo-data-query'
import { Grid } from '@progress/kendo-vue-grid'
import GridHelper from '@/utils/gridUtils'
import ContactService from '@/service/contact.service'

export default {
  components: {
    BModal,
    BButton,
    Grid,
  },
  data() {
    return {
      modalShow: false,
      selectedField: 'selected',
      selectedId: null,
      dataItems: [],
      dataState: GridHelper.createDefaultDataState(),
      columns: [
        {
          field: 'lastname',
          title: 'Lastname',
          width: '300px',
        },
        {
          field: 'firstname',
          title: 'Firstname',
          width: '300px',
        },
        {
          field: 'email',
          title: 'Email',
          width: '300px',
        },
        {
          field: 'phone',
          title: 'Phone',
          width: '300px',
        },
        {
          field: 'jobTitle',
          title: 'Job title',
          width: '300px',
        },
      ],
    }
  },
  computed: {
    result() {
      const items = this.dataItems.map(item => ({ ...item, selected: item.id === this.selectedId }))
      return process(items, this.dataState)
    },
    selectedItem() {
      return this.dataItems.find(item => item.id === this.selectedId)
    },
  },
  methods: {
    loadData() {
      this.selectedId = null
      ContactService.getAllListAsync().then(result => {
        this.dataItems = result
      })
    },
    dataStateChange(e) {
      this.dataState = e.data
    },
    onRowClick(event) {
      this.selectedId = event.dataItem.id
    },
    useSelected() {
      if (this.selectedId === null) {
        this.$swal(
          'No contact selected.',
          'Please select a contact or cancel the dialog.',
          'warning',
        )
      } else {
        this.$emit('selected', {
          id: this.selectedId,
          data: this.dataItems.find(item => item.id === this.selectedId),
        })

        this.modalShow = false
      }
    },
  },
}
</script>
